import { runsOnServerSide } from "@/shared/utils/helpers"
import i18n from "i18next"
import i18next from "i18next"
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector"
import {
  type UseTranslationOptions,
  useTranslation as useTranslationOrg,
  initReactI18next,
} from "react-i18next"
import { TrackJS } from "trackjs"

import { fallbackLng, getOptions, languages } from "./settings"

i18n
  .use(initReactI18next)
  .use(I18nextBrowserLanguageDetector)
  .init({
    ...getOptions(),
    lng: undefined, // let detect the language on client side
    detection: {
      order: ["cookie", "localStorage"],
      caches: ["cookie", "localStorage"],
    },
    preload: runsOnServerSide ? languages : [],
  })
  .then(() => {
    TrackJS.addMetadata("language", i18next.language)
  })
  .catch((err) => {
    console.error("i18n init", err)
  })

export function useClientTranslation(ns?: string, options?: UseTranslationOptions<any>) {
  const ret = useTranslationOrg(ns, options)
  const { i18n } = ret
  const activeLng = i18n.resolvedLanguage

  if (!activeLng) {
    void i18n.changeLanguage(fallbackLng)
  }

  return ret
}

export { i18n }
