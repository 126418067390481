/* eslint-disable import/no-anonymous-default-export */
import coveragePage from "./coverage-page.json"
import modalitiesPage from "./modalities-page.json"
import prepackagesPage from "./prepackages-page.json"
import pricingPage from "./pricing-page.json"
import tribeQuotePage from "./tribe-quote-page.json"

export default {
  "coverage-page": coveragePage,
  "modalities-page": modalitiesPage,
  "pricing-page": pricingPage,
  "tribe-quote-page": tribeQuotePage,
  "prepackages-page": prepackagesPage,
}
