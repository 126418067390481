/* eslint-disable import/no-anonymous-default-export */
import attribitionQuestionPage from "./attribution-question-page.json"
import ownerAddressPage from "./owner-address-page.json"
import ownerBirthdatePage from "./owner-birthdate-page.json"
import ownerMailPhonePage from "./owner-mail-phone-page.json"
import ownerNamePage from "./owner-name-page.json"

export default {
  "owner-name-page": ownerNamePage,
  "owner-address-page": ownerAddressPage,
  "owner-mail-phone-page": ownerMailPhonePage,
  "owner-birthdate-page": ownerBirthdatePage,
  "attribution-question-page": attribitionQuestionPage,
}
