/* eslint-disable import/no-anonymous-default-export */
import petBirthdatePage from "./pet-birthdate-page.json"
import petBreedPage from "./pet-breed-page.json"
import petNamePage from "./pet-name-page.json"
import petSexPage from "./pet-sex-page.json"
import petTypePage from "./pet-type-page.json"
import tribePage from "./tribe-page.json"

export default {
  "pet-name-page": petNamePage,
  "pet-birthdate-page": petBirthdatePage,
  "pet-sex-page": petSexPage,
  "pet-type-page": petTypePage,
  "pet-breed-page": petBreedPage,
  "tribe-page": tribePage,
}
