/* eslint-disable import/no-anonymous-default-export */
import ownerAddressPage from "./owner-address-page.json"
import ownerBirthdatePage from "./owner-birthdate-page.json"
import ownerMailPhonePage from "./owner-mail-phone-page.json"
import ownerNamePage from "./owner-name-page.json"
import attribitionQuestionPage from './attribution-question-page.json'

export default {
  "owner-name-page": ownerNamePage,
  "owner-address-page": ownerAddressPage,
  "owner-mail-phone-page": ownerMailPhonePage,
  "owner-birthdate-page": ownerBirthdatePage,
  "attribution-question-page": attribitionQuestionPage,
}
